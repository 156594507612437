// Avoid reloading and registering cacheBust modules
const cachedImportFns = new Map();
const MAX_RETRIES = 2;
const ENDPOINT_SCHEMA = {
  default: {
    getRequest: 'function',
    getResponse: 'function'
  }
};

export const parseModulePath = importFn => importFn.toString().match(/['"](.*)['"]/)[1];
export const importModuleWithRetry = async (importFn, retries = 0, moduleBasePath) => {
  try {
    const module = await importFn();
    // Save successful retry
    if (retries > 0 && moduleBasePath) {
      cachedImportFns.set(moduleBasePath, importFn);
    }

    return module;
  } catch (error) {
    if (retries < MAX_RETRIES) {
      const modulePath = moduleBasePath || parseModulePath(importFn);

      if (typeof cachedImportFns.get(modulePath) === 'function') {
        return cachedImportFns.get(modulePath)();
      }

      // eslint-disable-next-line no-undef
      await new Promise(resolve => setTimeout(resolve, 500));
      const cacheBustingPath = `${modulePath}?cacheBust=${Date.now()}`;

      return importModuleWithRetry(() => import(cacheBustingPath), retries + 1, modulePath);
    }
    throw error;
  }
};

const validate = (module, schema) => {
  for (const field of Object.keys(schema)) {
    const expectedType = typeof schema[field] === 'string' ? schema[field] : typeof schema[field];
    if (!(field in module)) {
      return `Missing field: ${field}`;
    }

    if (typeof module[field] !== expectedType) {
      return `Invalid type for field ${field}. Expected ${expectedType}, got ${typeof module[field]}`;
    }

    if (expectedType === 'object' && typeof schema[field] === 'object') {
      const errorMsg = validate(module[field], schema[field]);
      if (errorMsg) return errorMsg;
    }
  }

  return '';
};

export const importEndpointModule = async (importFn, name) => {
  let module = await importModuleWithRetry(importFn);

  let validationMsg = validate(module, ENDPOINT_SCHEMA);
  if (validationMsg) {
    const modulePath = parseModulePath(importFn);
    if (typeof cachedImportFns.get(modulePath) === 'function') {
      module = await cachedImportFns.get(modulePath)();
      validationMsg = validate(module, ENDPOINT_SCHEMA);
    }
    if (validationMsg) {
      cachedImportFns.delete(modulePath);
      const cacheBustingPath = `${modulePath}?cacheBust=${Date.now()}`;
      module = await importModuleWithRetry(() => import(cacheBustingPath), MAX_RETRIES, modulePath);
      validationMsg = validate(module, ENDPOINT_SCHEMA);
    }
  }

  if (validationMsg) {
    throw new Error(`Validation error for the ${name} endpoint module: ${validationMsg}. Import fn: ${importFn}`);
  }

  return module;
};
