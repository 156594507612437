export const _listAnonymousWishlists = () =>
  import('@oracle-cx-commerce/vcs-store/src/plugins/actions/vcs-anonymous-wish-list/actions');
export const _addProductToAnonymousWishlist = () =>
  import('@oracle-cx-commerce/vcs-store/src/plugins/actions/vcs-anonymous-wish-list/actions');
export const _deleteAnonymousWishlistProduct = () =>
  import('@oracle-cx-commerce/vcs-store/src/plugins/actions/vcs-anonymous-wish-list/actions');
export const _deleteAnonymousWishlist = () =>
  import('@oracle-cx-commerce/vcs-store/src/plugins/actions/vcs-anonymous-wish-list/actions');
export const _deleteAnonymousWishlists = () =>
  import('@oracle-cx-commerce/vcs-store/src/plugins/actions/vcs-anonymous-wish-list/actions');
