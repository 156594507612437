import {importEndpointModule} from '@oracle-cx-commerce/vcs-store/src/substitution/utils';

const fetchWithRetry = async (fetch, request) => {
  let response;
  try {
    response = await fetch(request.clone());
  } catch (e) {
    const isFetchError = e instanceof TypeError && e.message === 'Failed to fetch';
    if ((isFetchError && request.url.includes('/orders/current/submit')) || !isFetchError) throw e;

    // eslint-disable-next-line no-undef
    await new Promise(resolve => setTimeout(resolve, 500));
    response = await fetch(request.clone());
  }

  return response;
};

export const createWapi = ({endpoints}) => {
  console.assert(endpoints, `Missing argument "endpoints"`);

  const wapi = {
    async endpoint(name, payload, state) {
      if (typeof endpoints[name] !== 'function') {
        console.assert(typeof endpoints[name] === 'function', `Missing loader for endpoint "${name}"`);
        throw new Error(`Missing loader for endpoint "${name}"`);
      }

      // eslint-disable-next-line no-undef
      const fetchFunction = typeof self !== 'undefined' ? self.fetch : state.global.fetch;

      if (typeof fetchFunction !== 'function') {
        throw new Error(`Missing "fetch" function`);
      }

      const {getRequest, getResponse} = (await importEndpointModule(endpoints[name], name)).default;
      if (typeof getRequest !== 'function') {
        throw new Error(`getRequest is not a function for ${name}`);
      }

      const requestObj = await getRequest(payload, state, fetchFunction);

      const fetchResponse = await fetchWithRetry(fetchFunction, requestObj);

      if (typeof getResponse !== 'function') {
        throw new Error(`getResponse is not a function for ${name}`);
      }
      const processedResponse = await getResponse(fetchResponse, state, payload);

      return processedResponse;
    }
  };

  return wapi;
};
